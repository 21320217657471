import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { Accordion } from 'react-accessible-accordion'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  cta?: ButtonProps
  items: ItemProps[]
  title?: string
}

export const Accordions = memo(function Accordions({
  cta,
  items,
  title,
}: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      <Wrapper row wrap>
        <LeftSide>
          {title ? <Title>{title}</Title> : null}
          {cta ? <CTA variant="outline" {...cta} /> : null}
        </LeftSide>
        <RightSide>
          <Accordion allowZeroExpanded>
            {items.map((item, index) => (
              <Item key={index} isSpa {...item} />
            ))}
          </Accordion>
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 9rem 11.944vw;

  @media (max-width: 1199px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 1023px) {
    padding-top: 6.75rem;
    padding-bottom: 7.5rem;
  }
`

const Wrapper = styled(FlexBox)``

const LeftSide = styled.div`
  width: 40%;

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 2.5rem;
    text-align: center;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  text-transform: uppercase;
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`

const RightSide = styled.div`
  width: 60%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`
